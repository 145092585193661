import React from 'react';
import styled from 'styled-components';

import {
	getComponentSettings,
	priceFormatting,
	removeSpecialCharacters,
} from 'libs/content';
import MediumWidth from 'layouts/medium-width';
import Tabs from './Tabs';
import MaxWidth from 'layouts/max-width';
import AccordionGroup from './AccordionGroup';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';

//#region Styling
const Wrapper = styled.div`
	> div {
		padding: 0;
	}
	.spacing .max-width {
		padding: 0;
	}
`;

const List = styled.div`
	min-height: 50px;
	max-width: ${p => p.theme.widths.medium};
	background: transparent;
	margin: 0;
`;
//#endregion

/**
 * Accordion component that displays a list of collapsible items.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {string} props.title - The title of the accordion.
 * @param {string} props.stickTitle - The stick-title of the accordion.
 * @param {Array} props.tabs - The tabs to be displayed in the accordion.
 * @param {string} props.intro - The introduction text for the accordion.
 * @param {Array} [props.settings=[]] - The settings for the accordion.
 * @param {string} [props.headinglevel='h2'] - The HTML heading level for the accordion title.
 * @returns {React.Element} The rendered Accordion component.
 */
export default function Accordion({
	title,
	stickTitle,
	tabs,
	intro,
	settings = [],
	headinglevel = 'h2',
	...props
}) {
	const componentSettings = getComponentSettings({ settings });

	if (!tabs?.length > 0) return;

	// Parse accordion data
	const tabList = parseAccordionData(tabs, title);

	if (!tabList > 0) return;

	return (
		<MaxWidth
			className="component__accordion"
			data-cy="component__accordion">
			<Spacing {...props?.spacing}>
				<Wrapper>
					<ConditionalWrapper
						condition={componentSettings?.astabs !== 'true'}
						wrapper={children => (
							<MediumWidth>{children}</MediumWidth>
						)}>
						{componentSettings?.hidetitle !== 'true' &&
							componentSettings?.textbelow !== 'true' && (
								<Intro
									title={title}
									stickTitle={stickTitle}
									text={intro}
									headinglevel={headinglevel}
									settings={settings}
									transitions={props?.transitions}
								/>
							)}

						{componentSettings?.astabs === 'true' ? (
							<Tabs tabs={tabList} {...props} />
						) : (
							<List role="tablist" aria-label={title}>
								<AccordionGroup
									accordionId={removeSpecialCharacters(title)}
									accordions={tabList}
									transitions={props?.transitions}
									tabLimit={20}
									{...componentSettings}
								/>
							</List>
						)}
					</ConditionalWrapper>

					{componentSettings?.hidetitle === 'false' &&
						componentSettings?.textbelow === 'true' && (
							<Intro
								placedBelow={true}
								width="medium"
								text={intro}
								headinglevel={headinglevel}
								componentSettings={componentSettings}
								transitions={props?.transitions}
								style={{ marginTop: '45px' }}
							/>
						)}
				</Wrapper>
			</Spacing>
		</MaxWidth>
	);
}

/**
 * Represents the intro section of the accordion component.
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} [props.placedBelow=false] - If the intro should be placed below the accordion.
 * @param {string} [props.width='default'] - The width of the intro. (default|medium)
 * @returns {React.Element} The rendered component.
 */
function Intro({ placedBelow = false, width = 'default', ...props }) {
	const ComponentIntroProps = {
		...props,
		title: (placedBelow && null) || props?.title,
		stickTitle: (placedBelow && null) || props?.stickTitle,
		text: props?.text,
	};

	return (
		<ConditionalWrapper
			condition={width === 'medium'}
			wrapper={children => <MediumWidth>{children}</MediumWidth>}>
			<TitleAndText
				{...ComponentIntroProps}
				settings={props?.settings}
				nested={true}
			/>
		</ConditionalWrapper>
	);
}

/**
 * Conditional wrapper for the intro component.
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.condition - The condition to be met.
 * @param {Function} props.wrapper - The wrapper function.
 * @param {React.Element}
 * @returns {React.Element} The rendered component.
 */
function ConditionalWrapper({ condition, wrapper, children }) {
	return condition ? wrapper(children) : children;
}

/**
 * Parses the accordion data.
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.tabs - The tabs to be displayed in the accordion.
 * @param {string} props.title - The title of the accordion.
 * @returns {Array} The parsed accordion data.
 **/
function parseAccordionData(tabs, title) {
	if (!tabs?.length > 0) return;

	const items = [];

	// Loop through all items in accordion
	tabs.forEach((tab, i) => {
		// Komponent - Innhold
		if (['ContentfulKomponentInnhold'].includes(tab?.__typename)) {
			items.push({
				type: tab?.__typename,
				title: tab?.title,
				tabId: `${removeSpecialCharacters(
					title
				)}-${removeSpecialCharacters(tab?.title)}`,
				open: tab?.open || false,
				text: tab?.answer || tab?.content,
			});
		}

		// Komponent - Bilde og Innhold
		if (tab?.__typename === 'ContentfulKomponentBildeOgInnhold') {
			items.push({
				type: tab?.__typename,
				title: tab?.title,
				open: tab?.open || false,
				text: tab?.content,
				image: {
					...tab?.image,
					position: tab?.imageposition,
					fit: tab?.imageBehaviour,
				},
				buttons: tab?.buttons,
				settings: tab?.settings,
			});
		}

		// Innhold - Telekomprodukt
		if (tab?.__typename === 'ContentfulInnholdTelekomProdukter') {
			items.push({
				type: tab?.__typename,
				title: tab?.title,
				open: tab?.open || false,
				text: (
					<>
						<p>{tab?.shortDesc}</p>
						{(tab?.prices?.length === 1 && (
							<p>{priceFormatting(tab?.prices[0])} /mnd</p>
						)) ||
							(tab?.prices?.length > 1 && (
								<ul>
									{tab?.prices?.map((p, i) => (
										<li key={i}>{p}</li>
									))}
								</ul>
							))}
					</>
				),
			});
		}
	});

	return items;
}
