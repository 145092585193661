import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import { trackBackEnd } from 'context/AnalyticsProvider';
import { ContentToRichText, removeSpecialCharacters } from 'libs/content';
import { useScrollToTabs } from 'components/kundeservice/useScrollToTabs';
import ListStyling from 'libs/SharedStyling';
import BlankButton from 'components/forms/BlankButton';

//#region Styling
const TabButton = styled.button`
	position: relative;
	width: 100%;
	padding: ${p => p.theme.spacing.desktop.small};
	background: ${p => p.theme.colors.blue200};
	border-radius: ${p => p.theme.utils.borderRadius};
	border: 2px solid transparent;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${p => p.theme.colors.blue700};
	font-weight: 500;
	text-align: left;
	font-size: 20px;
	line-height: 26px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: ${p => p.theme.spacing.desktop.xsmall};
		`)}
	span {
		padding-right: ${p => p.theme.spacing.desktop.xxsmall};
	}
	&:focus {
		outline: 0;
	}
	&:hover {
		background: ${p => p.theme.colors.blue200};
		border: 2px solid ${p => p.theme.colors.blue400};
	}
`;

const Icon = styled.div`
	margin-right: ${p => p.theme.spacing.desktop.xxsmall};
	svg {
		vertical-align: bottom;
	}
`;

const Arrow = styled(FontAwesomeIcon)`
	margin-left: auto;
`;

const Tab = styled.div`
	margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
	${p =>
		p.$active &&
		css`
			box-shadow: ${p => p.theme.utils.boxShadow};
			background: ${p => p.theme.colors.white};
			> ${TabButton} {
				background: ${p => p.theme.colors.white};
				color: ${p => p.theme.colors.grey900};
				font-weight: 500;
				&:hover {
					border: 2px solid transparent;
				}
				svg {
					transform: rotate(180deg);
				}
			}
		`}
`;

const InnerContent = styled(motion.div)`
	overflow: hidden;
	> div {
		${ListStyling}
		padding: ${p =>
			`0 ${p.theme.spacing.desktop.small} ${p.theme.spacing.desktop.small} ${p.theme.spacing.desktop.small}`};
		${p =>
			p.theme.media.smallOnly(css`
				padding: ${p =>
					`0 ${p.theme.spacing.mobile.small} ${p.theme.spacing.mobile.small} ${p.theme.spacing.mobile.small}`};
			`)}
		ul:not(.tags):not(.job-vacancies) li svg {
			top: 0 !important;
		}
		ul:not(.tags):not(.job-vacancies) li ul li svg {
			top: 3px !important;
		}
		> :last-child {
			margin-bottom: 0 !important;
		}
	}

	.spacing {
		padding-bottom: 0 !important;
	}

	.component__related-content.max-width {
		padding: 0;
	}

	.boxes {
		display: block;
		> div {
			padding: 0 !important;
		}
	}
`;

const ShowAllBtn = styled(BlankButton)`
	margin-top: ${p => p.theme.spacing.desktop.small};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0;
	background-color: transparent;
	border: none;
	color: ${p => p.theme.colors.blue600};
	svg {
		margin-right: ${p => p.theme.spacing.desktop.xxsmall};
	}
	&:hover {
		padding: 0;
		background-color: transparent;
		border: none;
		color: ${p => p.theme.colors.blue800};
	}
`;
//#endregion

const variants = {
	open: {
		opacity: 1,
		height: 'auto',
		transition: { when: 'beforeChildren' },
	},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: { when: 'beforeChildren' },
	},
};

/**
 * Functional component representing an accordion UI element with support for smooth scrolling based on URL parameters.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {Array} props.accordions - An array of objects representing accordion data.
 * @param {string} [props.accordionId] - An optional ID for the accordion.
 * @param {string} [props.onlyoneopen='true'] - Whether only one accordion can be open at a time (default is 'true').
 *
 * @returns {JSX.Element} Returns the rendered AccordionGroup component.
 */
export default function AccordionGroup({ ...props }) {
	// Get tabs that are previously opened
	const preOpenedTabs = props?.accordions?.filter(a => a.open) || [];

	// If only one tab should be open, take the first one. Otherwise, take all.
	const tabsToOpen =
		props?.onlyoneopen === 'true' ? [preOpenedTabs[0]] : preOpenedTabs;

	// Define state with tabs that are previously opened as array, or empty array
	const [openTabs, setOpenTabs] = useState(tabsToOpen?.map(t => t?.tabId));

	const [openChildTabs, setOpenChildTabs] = useState([]);
	const [showAllTabs, setShowAllTabs] = useState(false);

	const tabLimit = props?.tabLimit || 6;
	const restDivId = props?.accordionId
		? `restdiv-${props?.accordionId}`
		: `restdiv-${Math.floor(Math.random() * 100)}`;

	// Run hook for smooth scroll to accordion based on url-param
	useScrollToTabs({
		props,
		openTabs,
		setOpenTabs,
		openChildTabs,
		setOpenChildTabs,
		setShowAllTabs,
		tabLimit,
	});

	// If is set to open first tab as default
	useEffect(() => {
		if (!props?.accordions?.length > 0) return;

		// If already open then close it
		if (props?.firsttabopen === 'true') {
			setOpenTabs([...openTabs, props?.accordions[0]?.tabId]);
		} else {
			setOpenTabs(
				openTabs.filter(tab => tab !== props?.accordions[0]?.tabId)
			);
		}
		//eslint-disable-next-line
	}, [props?.firsttabopen]);

	if (!props?.accordions?.length > 0) return;

	return (
		<Fragment>
			{props?.accordions?.map((acc, i) => {
				if (i + 1 > tabLimit) return null;
				return (
					<Accordion
						key={i}
						children={acc?.faqs || []}
						openTabs={openTabs}
						setOpenTabs={setOpenTabs}
						openChildTabs={openChildTabs}
						setOpenChildTabs={setOpenChildTabs}
						groupTitle={props?.title}
						{...props}
						accordions={props?.accordions}
						{...acc}
					/>
				);
			})}

			<AnimatePresence initial={false}>
				{showAllTabs && props?.accordions?.length > tabLimit && (
					<motion.div
						id={restDivId}
						key={restDivId}
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={variants}
						transition={{
							duration: 0.25,
							delay: 0.25,
						}}>
						{props?.accordions?.map((acc, i) => {
							if (i + 1 <= tabLimit) return null;
							return (
								<Accordion
									key={i}
									children={acc?.faqs || []}
									openTabs={openTabs}
									setOpenTabs={setOpenTabs}
									openChildTabs={openChildTabs}
									setOpenChildTabs={setOpenChildTabs}
									groupTitle={props?.title}
									{...props}
									accordions={props?.accordions}
									{...acc}
								/>
							);
						})}
					</motion.div>
				)}
			</AnimatePresence>

			{props?.accordions?.length > tabLimit && (
				<ShowAllBtn
					aria-expanded={showAllTabs}
					onClick={() => {
						setShowAllTabs(!showAllTabs);
					}}>
					<FontAwesomeIcon
						icon={(showAllTabs && faMinus) || faPlus}
					/>
					<span>{(showAllTabs && 'Vis færre') || 'Vis alle'}</span>
				</ShowAllBtn>
			)}
		</Fragment>
	);
}

/**
 * Functional component representing an accordion UI element.
 *
 * @function
 * @param {Object} props - The component's properties.
 * @param {string} [props.accordionId] - An optional ID for the accordion.
 * @param {string} [props.title] - The title of the accordion.
 * @param {string} [props.text] - The text content of the accordion.
 * @param {Array} [props.children] - An array of child accordions.
 * @returns {JSX.Element} Returns the rendered Accordion component.
 *
 */
export function Accordion({ ...props }) {
	const tabId = `${removeSpecialCharacters(
		props?.accordionId
	)}-${removeSpecialCharacters(props?.title)}`;

	return (
		<SingleAccordion level={1} {...props} tabId={tabId}>
			{(props?.text?.raw && <ContentToRichText data={props?.text} />) || (
				<>{props?.text}</>
			)}

			{props?.children?.length > 0 &&
				props?.children?.map((child, i) => (
					<SingleAccordion
						key={i}
						{...child}
						{...props}
						title={child?.title}
						tabId={`${tabId}-${removeSpecialCharacters(
							child?.title
						)}`}
						level={2}>
						{(child?.text?.raw && (
							<ContentToRichText data={child?.text} />
						)) || <>{child?.text}</>}
					</SingleAccordion>
				))}
		</SingleAccordion>
	);
}

/**
 * Functional component representing a single accordion item.
 *
 * @function
 * @param {Object} props - The component's properties.
 * @param {string} [props.accordionId] - An optional ID for the accordion item.
 * @param {string} [props.title] - The title of the accordion item.
 * @param {ReactNode} children - The content to be displayed inside the accordion item.
 * @param {number} [props.level=1] - The level of the accordion item (1 for top-level, 2 for child items).
 * @param {Array} [props.openTabs] - An array of IDs representing open top-level accordion items.
 * @param {Function} [props.setOpenTabs] - A function to update the open top-level accordion items.
 * @param {Array} [props.openChildTabs] - An array of IDs representing open child accordion items.
 * @param {Function} [props.setOpenChildTabs] - A function to update the open child accordion items.
 * @returns {JSX.Element} Returns the rendered SingleAccordion component.
 *
 */
export function SingleAccordion({ children, ...props }) {
	const isChild = props?.level === 2;

	const open = (isChild && props?.openChildTabs) || props?.openTabs;
	const setOpen = (isChild && props?.setOpenChildTabs) || props?.setOpenTabs;
	const isOpen = open?.length > 0 && open?.some(tab => tab === props?.tabId);

	return (
		<Tab $active={isOpen} id={props?.tabId}>
			<TabButton
				onClick={() => {
					const toggleProps = {
						id: props?.tabId,
						open,
						setOpen,
						onlyoneopen: props?.onlyoneopen,
						group: removeSpecialCharacters(props?.accordionId),
						accordion: removeSpecialCharacters(props?.title),
					};

					if (isOpen) {
						handleCloseTab(toggleProps);
					} else {
						handleOpenTab(toggleProps);
					}
				}}>
				{props?.icon && <Icon>{props?.icon}</Icon>}
				<span>{props?.title}</span>
				<Arrow
					icon={faChevronDown}
					title={(isOpen && 'Lukk tab') || 'Åpne tab'}
					width="26"
					height="26"
				/>
			</TabButton>
			<AnimatePresence initial={false}>
				{isOpen && (
					<InnerContent
						key={props?.tabId}
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={variants}
						transition={{
							duration: 0.25,
						}}>
						<div>
							<div>{children}</div>
						</div>
					</InnerContent>
				)}
			</AnimatePresence>
		</Tab>
	);
}

/**
 * Handles opening a tab identified by its unique ID in the specified tab array.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.id - The unique ID of the tab to be opened.
 * @param {Array} [options.open=[]] - The array of currently open tabs.
 * @param {Function} options.setOpen - The function to update the open tabs array.
 * @param {string} [options.onlyoneopen='false'] - Whether only one tab is allowed to be open at a time (default is 'false').
 * @param {string} [options.context='Click'] - The context in which the tab is being opened (e.g., 'Click', 'Page load').
 * @param {string} [options.group=''] - The group identifier of the tab (if applicable).
 * @param {string} [options.accordion=''] - The accordion identifier of the tab (if applicable).
 *
 */
export function handleOpenTab({
	id,
	open = [],
	setOpen,
	onlyoneopen = 'false',
	context = 'Click',
	group = '',
	accordion = '',
}) {
	if (!id || !open || !setOpen) return;

	// If already open don't proceed
	if (open?.some(tab => tab === id)) {
		return;
	}

	// If only one tab allowed to be open at a time then overwrite
	if (onlyoneopen === 'true') {
		setOpen([id]);

		// Otherwise add to array
	} else {
		setOpen([...open, id]);
	}

	// Track opening of tab
	if (group || accordion) {
		trackBackEnd('Faq Opened', {
			group: group || undefined,
			accordion: accordion || undefined,
			context,
		});
	}
}

/**
 * Handles closing a tab identified by its unique ID in the specified tab array.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.id - The unique ID of the tab to be closed.
 * @param {Array} [options.open=[]] - The array of currently open tabs.
 * @param {Function} options.setOpen - The function to update the open tabs array.
 * @param {string} [options.context='Click'] - The context in which the tab is being closed (e.g., 'Click', 'Page load').
 * @param {string} [options.group=''] - The group identifier of the tab (if applicable).
 * @param {string} [options.accordion=''] - The accordion identifier of the tab (if applicable).
 */
export function handleCloseTab({
	id,
	open = [],
	setOpen,
	context = 'Click',
	group = '',
	accordion = '',
}) {
	if (!id || !open?.length > 0 || !setOpen) return;

	// Remove the id from the array of open tabs
	setOpen(open.filter(tab => tab !== id));

	// Track closing of tab
	if (group || accordion) {
		trackBackEnd('Faq Closed', {
			group: group || undefined,
			accordion: accordion || undefined,
			context,
		});
	}
}
